import { infoStableSwapClient } from '../../utils/graphql'
import {
  INFO_CLIENT_BSC,
  BLOCKS_CLIENT_BSC,
  INFO_CLIENT_TBSC,
  BLOCKS_CLIENT_TBSC,
} from '../../config/constants/endpoints'
import { ChainId } from '@pancakeswap/sdk'
import { PCS_TBSC_START } from '../../config/constants/info'
import { GraphQLClient } from 'graphql-request'

export type MultiChainName = 'BSC' | 'TBSC'

export const multiChainQueryMainToken = {
  BSC: 'BNB',
  TBSC: 'BNB',
}

export const multiChainBlocksClient = {
  BSC: BLOCKS_CLIENT_BSC,
  TBSC: BLOCKS_CLIENT_TBSC,
}

export const multiChainStartTime = {
  TBSC: PCS_TBSC_START,
}

export const multiChainId = {
  TBSC: ChainId.TBSC,
  BSC: ChainId.BSC,
}

export const multiChainPaths = {
  [ChainId.TBSC]: '',
  [ChainId.BSC]: '',
}

// @ts-ignore fix missing queryClients
export const multiChainQueryClient: Record<MultiChainName, GraphQLClient> = {
  TBSC: new GraphQLClient(INFO_CLIENT_TBSC),
  BSC: new GraphQLClient(INFO_CLIENT_BSC),
}

export const multiChainQueryEndPoint = {
  TBSC: INFO_CLIENT_TBSC,
  BSC: INFO_CLIENT_BSC,
}

export const multiChainScan = {
  BSC: 'BscScan',
  TBSC: 'Test BscScan',
}

export const multiChainTokenBlackList = {
  TBSC: [''],
  BSC: [''],
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName) => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
